import React from 'react'

export default function Footer() {
    return (
        <>
        {/* <div className="page-footer bg-card mt-4 shadow-sm">
          <div className="container-fluid">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-2">
              <p className="col-md-5 mb-0 text-muted">© 2024 Kaspa</p>
            </footer>
          </div>
        </div> */}
        </>
      );
}
