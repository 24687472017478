import React, { useCallback, useContext, useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { ConnectContext } from '../context/ConnectContext';
import { NetworkContext } from '../context/NetworkContext';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { getKasPrice } from '../utils'
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const config = require('../config.json')

export default function Dash({ipAddress, loginData}) {
  const [provider] = useContext(ConnectContext)
  const [account] = useContext(NetworkContext)
  const [loading, setLoading] = useContext(LoadingContext)
  const [referall, setReferall] = useState("")
  const [dash, setDash] = useState({})
  const [price, setPrice] = useState(0);
  const history = useHistory();

  const handleDash = useCallback(async () => {
        setLoading(true)
    
        let data = JSON.stringify({
            "address": (loginData.address)?loginData.address:account,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });
        let axiosConfig = {
            method: 'post',
            url: `${config.baseUrl}api/desk`,
            headers: {
                'address': (loginData.address)?loginData.address:account, 
                'ip': ipAddress, 
                'ulid': loginData.ulid, 
                'auth': loginData.auth, 
                'token': loginData.token, 
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(axiosConfig)
        let response = await axios.request(axiosConfig)
        console.log(response)
        response = response.data
        setLoading(false)
        if (response.status) {
            console.log(response.data)
            setDash(response.info)
        }
        else if (response.code === 30) {
            Swal.fire({
                icon: 'info',
                title: 'kaspa Miners',
                text: response?.message
            }).then(() => {
                history.push('/');
            })
        }
  }, [account, history, ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid, setLoading]);

  useEffect(()=>{
    handleDash()
    handelprice()
  },[handleDash])
  const handelprice = useCallback(async () => {
    let p = await getKasPrice(); 
    setPrice(parseFloat(p).toFixed(3))
  }, []);

    return (
        <>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <div className="page-body m-10">
                <div className="container-fluid">
                    <div className="row g-xl-4 g-lg-3 g-2 row-deck">
                        <div className="col-xxl-12 col-xl-12 col-lg-12">
                            <div className="card shadow-sm">
                                <div className="card-body doctor-detail order-1 order-md-0">
                                    <h6 className='text-center'>Dear, {loginData?.name} ({loginData?.ulid}) </h6>
                                    <span>Welcome to Kaspa Miners. You can Buy any Package for Mining Kaspa</span>
                                    <div className="d-flex flex-row flex-wrap align-items-center mb-3 mt-2">
                                        <table style={{width : '100%'}}>
                                            <tr>
                                                <td>My Reffral Code</td>
                                                <td className='text-end'><b>{loginData?.ulid}</b></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                            <table style={{width : '100%'}}>
                                <tr className='mt-2'>
                                    <td><Link className="btn btn-rounded bg-info btn-sm m-2"  to='/buy'><i className='fa fa-shopping-cart'></i> BUY </Link></td>
                                    <td><Link className="btn btn-rounded bg-info btn-sm m-2"  to='/profile'><i className='fa fa-user'></i> Profile</Link></td>
                                    <td><Link className="btn btn-rounded bg-info btn-sm m-2"  to='/support'><i className='fa fa-tag'></i> Ticket</Link></td>
                                    <td><Link className="btn btn-rounded bg-info btn-sm m-2"  to='/downline'><i className='fa fa-users'></i> Team</Link></td>
                                    <td className='text-end'>
                                    <CopyToClipboard text={dash?.copyLink}>
                                        <button className="btn btn-info btn-sm m-2"><i className='fa fa-copy'></i> Copy Link</button>
                                    </CopyToClipboard>
                                    </td>
                                </tr>    
                            </table>
                            </div>
                            
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                
                                <div className="flex-fill text-truncate ">
                                    <div className="h6 mb-0">Total Invested</div>
                                    <span className="small">$ {dash?.total_invest}</span>
                                </div>
                                <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                
                                <div className="flex-fill text-truncate ">
                                    <div className="mb-0">Total Income</div>
                                </div>
                                <div className="flex-fill text-truncate text-end">
                                    <div className="mb-0">KAS {dash?.total} <span className='text-danger mt-0' style={{fontSize:'8px'}}>($ {parseFloat(price*dash?.total).toFixed(3)})</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                
                                <div className="flex-fill text-truncate ">
                                    <div className="mb-0">Today Income</div>
                                </div>
                                <div className="flex-fill text-truncate text-end">
                                    <div className="mb-0">KAS {dash?.today} <span className='text-danger mt-0' style={{fontSize:'8px'}}>($ {parseFloat(price*dash?.today).toFixed(3)} )</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                
                                <div className="flex-fill text-truncate ">
                                    <div className="mb-0">Associate Reward</div>
                                </div>
                                <div className="flex-fill text-truncate text-end">
                                    <div className="mb-0">KAS {dash?.passive} <span className='text-danger mt-0' style={{fontSize:'8px'}}>($ {parseFloat(price*dash?.passive).toFixed(3)} )</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                
                                <div className="flex-fill text-truncate ">
                                    <div className="mb-0">Mining Reward</div>
                                </div>
                                <div className="flex-fill text-truncate text-end">
                                    <div className="mb-0">KAS {dash?.gen} <span className='text-danger mt-0' style={{fontSize:'8px'}}>($ {parseFloat(price*dash?.gen).toFixed(3)} )</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                
                                <div className="flex-fill text-truncate ">
                                    <div className="mb-0">Wallet</div>
                                </div>
                                <div className="flex-fill text-truncate text-end">
                                    <div className="mb-0">KAS {dash?.balance} <span className='text-danger mt-0' style={{fontSize:'8px'}}>($ {parseFloat(price*dash?.balance).toFixed(3)})</span></div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                
                                <div className="flex-fill text-truncate ">
                                    <div className="mb-0">Total withdrawal</div>
                                </div>
                                <div className="flex-fill text-truncate text-end">
                                    <div className="mb-0">KAS {dash?.total_with} <span className='text-danger mt-0' style={{fontSize:'8px'}}>($ {parseFloat(price*dash?.total_with).toFixed(3)} )</span></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            }
            <Footer/>
        </>
    );
}
