import {ethers} from 'ethers'

const contract = async (provider,tokenAddress, tokenABI) => {
    let signer = provider.getSigner();
    let contract = new ethers.Contract(tokenAddress, tokenABI, signer);
    return contract;
  };

export const transfer = async(provider,tokenAddress,tokenAbi,recipientAddress,amount)=>{
  let cont = await contract(provider,tokenAddress,tokenAbi)
  console.log(cont)
  let res =  await cont.transfer(recipientAddress,amount)
  console.log(res);
  let conf = res.wait()
  return conf
}

export const balance = async(provider,userAddress,tokenAbi,contractAddress)=>{
  let cont = await contract(provider,contractAddress,tokenAbi)
  let res =  (await cont.balanceOf(userAddress)).toString()
  
  return res
}