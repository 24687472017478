import React, { useCallback, useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NetworkContext } from "../context/NetworkContext";
import { ConnectContext } from "../context/ConnectContext";
import web3Modal from ".././modal";

import axios from "axios";
import { MobileSidebarContext } from "../context/MobileSidebarContext";
import { getKasPrice, truncateAddress, getKasMindata } from "../utils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../images/kas.webp";
const config = require("../config.json");

export default function Header() {
  const [account, setAccount] = useContext(NetworkContext);
  const [provider, setProvider, checkNetwork] = useContext(ConnectContext);
  const history = useHistory();
  const [ipAddress, setIpAddress] = useState("1.1.1.1");
  const [mobileOpen, setMobileOpen] = useContext(MobileSidebarContext);
  const userData = JSON.parse(localStorage.getItem("loginData"));
  const [price, setPrice] = useState(0);
  const connectWallet = useCallback(async () => {
    try {
      // console.log("Wallet connect called");
      const instance = await web3Modal().connect();
      // setInstance(instance);
      let provider = new ethers.providers.Web3Provider(instance, "any");
      setProvider(provider);
      const accounts = await provider.listAccounts();
      if (accounts) {
        setAccount(accounts[0]);
      }
    } catch (error) {
      console.error(error?.message);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     text: error?.message
      //   })
    }
  }, [setAccount, setProvider]);
  const refreshState = useCallback(() => {
    setAccount();
  }, [setAccount]);

  const disconnectWallet = useCallback(async () => {
    try {
      web3Modal().clearCachedProvider();
      provider.removeAllListeners();
    } catch (error) {
      console.error(error);
    }
  }, [provider]);

  useEffect(() => {
    checkNetwork().then((res) => {
      if (!res) {
        handleLogout();
      }
    });
    handelprice();
  }, []);

  const handleLogout = useCallback(async () => {
    disconnectWallet();
    try {
      const loginData = JSON.parse(sessionStorage.getItem("loginData"));
      let data = JSON.stringify({
        address: loginData?.address ? loginData?.address : account,
        ip: ipAddress,
        ulid: loginData?.ulid,
      });

      let axiosConfig = {
        method: "post",
        url: `${config.baseUrl}/api/logout`,
        headers: {
          address: account,
          ip: ipAddress,
          ulid: "6049090",
          auth: loginData?.auth,
          token: loginData?.token,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let response = await axios.request(axiosConfig);
      response = response.data;
      //   console.log('logging out')
      console.log(response);
      sessionStorage.removeItem("loginData");
      history.push("/");
    } catch (error) {
      console.error(error);
    }
  }, [account, disconnectWallet, history, ipAddress]);

  const handelprice = useCallback(async () => {
    let p = await getKasPrice();
    setPrice(parseFloat(p).toFixed(3));
  }, []);

  return (
    <>
      <div className="body-header sticky-md-top fixed-top mb-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Link
              className="navbar-brand d-flex align-items-center color-900"
              to="/dash"
              style={{ width: "50%" }}
            >
              <img src={logo} style={{ width: "45%" }} alt="Logo" />
            </Link>
            <div className="menu-link flex-fill">
              <div className="dropdown menu-apps">
                <Link to="/dash" className="btn btn-link btn-sm after-none">
                  <svg
                    className="mx-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      className="fill-secondary"
                      d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"
                    />
                    <path
                      className="fill-muted"
                      fillRule="evenodd"
                      d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
                    />
                  </svg>
                  <span>Home</span>
                </Link>
              </div>
              <div className="dropdown menu-apps">
                <Link to="/buy" className="btn btn-link btn-sm after-none">
                <svg className="mx-1" viewBox="0 0 16 16" width={18}
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"></path>
                <path class="fill-secondary" d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
              </svg>
                  <span>Buy MRS</span>
                </Link>
              </div>
              <div className="dropdown menu-apps">
                <Link to="/miner" className="btn btn-link after-none">
                <svg class="mx-1" width={18} viewBox="0 0 16 16" fill="currentColor"  xmlns="http://www.w3.org/2000/svg">
                <path d="M1 11C1 10.7348 1.10536 10.4804 1.29289 10.2929C1.48043 10.1054 1.73478 10 2 10H4C4.26522 10 4.51957 10.1054 4.70711 10.2929C4.89464 10.4804 5 10.7348 5 11V14C5 14.2652 4.89464 14.5196 4.70711 14.7071C4.51957 14.8946 4.26522 15 4 15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V11ZM6 7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H9C9.26522 6 9.51957 6.10536 9.70711 6.29289C9.89464 6.48043 10 6.73478 10 7V14C10 14.2652 9.89464 14.5196 9.70711 14.7071C9.51957 14.8946 9.26522 15 9 15H7C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14V7Z" fill="black"></path>
                <path class="fill-secondary" d="M11.2929 1.29289C11.1054 1.48043 11 1.73478 11 2V14C11 14.2652 11.1054 14.5196 11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1H12C11.7348 1 11.4804 1.10536 11.2929 1.29289Z" fill="black"></path>
              </svg>
                  <span>Miner</span>
                </Link>
              </div>
              <div className="dropdown menu-pages">
                <Link to="/income" className="btn  btn-sm btn-link after-none">
                  <svg
                    className="mx-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      className="fill-secondary"
                      fillRule="evenodd"
                      d="M8.646 5.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 0 1 0-.708zm-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 8l1.647-1.646a.5.5 0 0 0 0-.708z"
                    />
                    <path
                      className="fill-muted"
                      d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"
                    />
                    <path
                      className="fill-muted"
                      d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"
                    />
                  </svg>
                  <span>Reward</span>
                </Link>
              </div>
              <div className="dropdown menu-resources">
                <Link to="/wallet" className="btn btn-sm btn-link after-none">
                  <svg
                    className="mx-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      className="fill-secondary"
                      d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                    />
                    <path
                      className="fill-muted"
                      d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"
                    />
                    <path
                      className="fill-muted"
                      d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"
                    />
                  </svg>
                  <span>wallet</span>
                </Link>
              </div>
            </div>
            <ul className="nav navbar-right d-flex align-items-center mb-0 list-unstyled">
              <li>
                <span
                  className="btn btn-sm  btn-info"
                  style={{ fontSize: "10px" }}
                >
                  KAS/USD : {price}
                </span>
              </li>

              <li>
                <div className="dropdown morphing scale-left user-profile mx-lg-3 mx-2">
                  {/* <button className="btn btn-sm bg-secondary dropdown-toggle after-none p-0" role="button" data-bs-toggle="dropdown">
                                {truncateAddress(account)}
                            </button> */}
                  <button
                    className="btn btn-sm btn-info"
                    data-bs-toggle="dropdown"
                    style={{ fontSize: "10px" }}
                  >
                    {truncateAddress(account)}
                  </button>
                  <div className="dropdown-menu border-0 rounded-4 shadow p-0">
                    <div className="card w240 overflow-hidden">
                      <div className="card-body text-center">
                        <h6 className="card-title mb-0">
                          {userData?.name} ({userData?.ulid})
                        </h6>
                        <p className="text-muted">{userData?.email}</p>
                        <button
                          onClick={handleLogout}
                          className="btn bg-secondary text-light text-uppercase w-100"
                        >
                          Logout
                        </button>
                      </div>
                      <div className="list-group m-2">
                        <Link
                          className="list-group-item list-group-item-action border-0"
                          to="/profile"
                        >
                          <i className="w30 fa fa-user" />
                          Profile &amp; account
                        </Link>
                        
                        <Link
                          className="list-group-item list-group-item-action border-0"
                          to="/support"
                        >
                          <i className="w30 fa fa-tag" />
                          Support Ticket
                        </Link>
                        <Link
                          className="list-group-item list-group-item-action border-0"
                          to="/downline"
                        >
                          <i className="w30 fa fa-users" />
                          Manage team
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
