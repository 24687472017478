import { useState, createContext, useEffect } from "react";
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import '../css/sweetalert-dark-theme.css';

export const IpContext = createContext();

export const IpProvider = ({ children }) => {
    
    const [ipAddress, setIpAddress] = useState('108:108:108:108');
    
    return (
        <IpContext.Provider value={[ipAddress, setIpAddress]}>
            {children}
        </IpContext.Provider>
    );
};