import axios from "axios"
import Swal from "sweetalert2"
const config = require('./config.json')
export const getKasPrice = async () => { //get Kaspa price
  let res = await axios.get('https://api.kaspa.org/info/price?stringOnly=false')
  console.log(res);
  let price = res.data.price
  return price
}

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const getKasMindata = async () => { //get busd price
  let res = await axios.get('https://kaspa-pool.org/api/user/base/?wallet=kaspa:qzumn5wkccdzqlt70c2gz7zu834p0jk48xpxhtstmy8k8ffaw552c46anhs0m')
  let info = res.data
  
  return info
  
}