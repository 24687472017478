import React, { useContext, useState, useEffect, useCallback } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { NetworkContext } from '../context/NetworkContext';
import { ConnectContext } from '../context/ConnectContext';
import { pool } from '../address';
import { poolABI } from '../abi';
import axios from 'axios';
import ReactLoader from '../components/ReactLoader';
import { LoadingContext } from '../context/LoadingContext';
import Swal from 'sweetalert2'
import { getKasPrice } from '../utils'
const config = require('../config.json')

export default function Request({ ipAddress, loginData }) {
  const [account] = useContext(NetworkContext);
  
  const [amount, setAmount] = useState(0.0)
  const [usd, setUsd] = useState(0.0)
  const [min, setMin] = useState(0.0)
  const [balance, SetBalance] = useState(0.0)
  const [provider] = useContext(ConnectContext)
  const [loading, setLoading] = useContext(LoadingContext)
  const [info, setInfo] = useState({})
  const [isPay, setispay] = useState(0);
  const [price, setPrice] = useState(0);
  const handleWallet = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/wallet`,
      headers: {
        'address': account,
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        SetBalance(response.data?.balance)
        setInfo(response.data)
        setispay(response.data.ispay)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {
    handelprice();
    handleWallet()
  }, [handleWallet])

  const handelprice = useCallback(async () => {
    let p = await getKasPrice(); 
    setPrice(parseFloat(p).toFixed(3))
  }, []);

  const handelAmount = (e) => {
    let amt = e.target.value
    if (amt > balance) {
      Swal.fire({
        icon: 'error',
        title: "Kaspa Miner",
        text: "Your Balance is Low. Please Check Balance."
      })
    }
    else
      if (amt < info.min) {
        Swal.fire({
          icon: 'error',
          title: "Kaspa Miner",
          text: "Please Enter valid Amount"
        })
        setAmount(0)
        setUsd(0*price)
      }
      else {
        setAmount(amt)
        setUsd(amt*price)
      }
  }

  const handelSubmit = () => {
    if (amount < info.min) {
        Swal.fire({
          icon: 'error',
          title: "Kaspa Miner",
          text: "Please Enter valid Amount"
        })
      }
      else {
          
          let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "usd": amount,
            "price" : price
          });

          let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/api/walletrequest`,
            headers: {
              'address': account,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
            },
            data: data
          };
          // console.log(axiosConfig)/
          axios.request(axiosConfig)
            .then((response) => {
              console.log(response)
              let res = response.data
              if (res.status) {
                Swal.fire({
                  icon: 'info',
                  title: 'Kaspa Miner ',
                  text: res.message
                });
                setAmount(0)
                

              }
              else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Kaspa Miner',
                  text: res.message
                });
              }
              handleWallet()
            })
            .catch((error) => {
              console.log(error);
            });
          }
  }

  return (
    <>
      
          <Header />
          {loading ? <><ReactLoader/></> :
          <div className="page-body m-10">
            <div className="container-fluid">
              {(isPay === 1) ? 
              <>
              <div className='row'>
                <div className="col-md-6  mb-2">
                    <div className="card shadow-sm">
                        <div className="card-body ">
                            <div className="mb-0 text-center">
                                <h6 className="m-0 me-2 text-info mb-2">Withdraw Information</h6>
                            </div>
                            <div className="mb-0 d-flex">
                                <div className="flex-fill text-truncate ">
                                    <div className="mb-0 text-info">Kaspa Wallet</div>
                                </div>
                                <div className="flex-fill text-truncate text-end">
                                    <p className="mb-0">KAS {balance}  <span className='text-danger mt-0' style={{fontSize:'10px'}}>($ {(price*balance).toFixed(3)})</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6  mb-2">
                    <div className="card shadow-sm">
                        <div className="card-body ">
                          {(info?.min > balance)?<></>: <>
                            <div className="mb-0 d-flex">
                                <div className="flex-fill text-truncate me-2">
                                    <input type='number' placeholder='Enter KAS amount'  className="form-control form-control-sm" onBlur={(e) => handelAmount(e)} />
                                </div>
                                <div className="flex-fill text-truncate text-end ms-2">
                                  <input type='text' placeholder={(usd === 0)?'USD amount':'USD amount'} className="form-control form-control-sm me-2" value={usd} readOnly />
                                </div>
                            </div>
                            <div className='text-center mt-4'>
                               <button className='btn  btn-warning btn-sm' onClick={() => handelSubmit()}>Withdraw</button>
                             </div>
                             </>
                             }
                             <div className='text-center mt-4'>
                              <small className="text-light text-center mb-0 text-warning">Minimum withdraw KAS : {info?.min} </small><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
              // <div className='row'>
              //   <div className="col-md-12  mb-3">
              //     <div className="card shadow-sm">
              //       <div className="">
              //         <div className="d-flex card-body">
              //           <div className=" mb-0">
              //             <p className='text-info mb-0' >Kaspa </p>
              //           </div>
              //           <div className="text-end">
              //             <p className='text-warning mb-0'>KAS {balance}</p>
              //           </div>
              //         </div>
              //         {/* <div className="d-flex align-items-center justify-content-between">
              //           <input type="text" className="form-control text-center" placeholder="USDT" value={amount} onChange={handelAmount} />
              //         </div> */}
              //         <div className='text-center mt-4'>
              //           <button className='btn  btn-warning btn-sm' onClick={() => handelSubmit()}>Withdraw</button>
              //         </div> 
              //         <div className='text-center mt-4'>
              //           <small className="text-light text-center mb-0 text-warning">Minimum withdraw KAS : {info?.min} </small><br />
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
              :
              <div className='row'>
                <div className="col-md-12  mb-3">
                  <div className="card ">
                    <div className="card-body align-items-center p-3">
                      <div className='text-center mt-4'>
                        <small className="text-light text-center mb-0 text-warning"> {info?.paymsg}</small><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }

              <div className='row'>
                <div className='col-md-12'>
                    <div className="card shadow-sm">
                        <p className="card-header">Withdraw Information</p>
                        <div className="card-datatable table-responsive text-center">
                            <table className=" table border-top table-xs table-hover table-bordered table-striped text-center" style={{fontSize : "10px"}}>
                                <thead >
                                    <tr>
                                        <th style={{fontSize:"11px"}}>Date</th>
                                        <th style={{fontSize:"11px"}}>Kas</th>
                                        <th style={{fontSize:"11px"}}>USD</th>
                                        <th style={{fontSize:"11px"}}>Status</th>
                                        <th style={{fontSize:"11px"}}>Trx Hash</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    info.list?.map((list, index)=>{
                                        
                                        return ( 
                                                <>
                                                    <tr key={index}>
                                                        <td>{list.rdate}</td>
                                                        <td>{list.netAmont}</td>
                                                        <td>{(list.netAmont * price).toFixed(3)}</td>
                                                        <td>{list.status}</td>
                                                        <td><a href={'https://explorer.kaspa.org/txs/'+list.thash} className='text-warning' target='blank'>Click to View</a></td>
                                                    </tr>
                                                </>      
                                        )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
           
          </div>
          }
           <Footer />
     
    </>
  );
}
