import Header from '../components/Header';
import Footer from '../components/Footer';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NetworkContext } from '../context/NetworkContext';
import axios from 'axios';
//import { send } from 'process';
import { ConnectContext } from '../context/ConnectContext';
import { ethers } from 'ethers';
import { UsdToken} from "../address";
import { UsdTokenABI as abi } from "../abi";
import { transfer, balance } from '../contract/token';
import ReactLoader from '../components/ReactLoader';
import { LoadingContext } from '../context/LoadingContext';
import Swal from 'sweetalert2'
const config = require('../config.json')
const recipientAddress = "0x63742c4d2771C269Ba56BC6B7E8b69Cd29389dba"


export default function Leverage({ ipAddress, loginData }) {
    const [account] = useContext(NetworkContext);
    const [wallet, setWallet] = useState('')
    const [rebuy, setRebuy] = useState(0)
    const [bal, setBal] = useState(0)
    const [provider] = useContext(ConnectContext)
    const [loading, setLoading] = useContext(LoadingContext)
   

    const sendToken = async (p) => {
        
        if(rebuy > 0 )
        {
             let amt = rebuy
                // console.log(amt, extraLkd, price)
                let amount  = ethers.utils.parseUnits(amt.toString())
                // console.log("amount",amount)
                let tx = await transfer(provider, UsdToken, abi, recipientAddress, amount)
                
                let dt= Date.now();
                Swal.fire({
                    title : "Kaspa Miner",
                    icon : 'info',
                    text : "Please Wait For transaction Processed"
                }) 
                if(tx.transactionHash)
                {
                    handleLeverage( dt, amt, tx.transactionHash, p);
                }
        }
        else
        {
            Swal.fire({
                title : "Kaspa Miner",
                icon : 'info',
                text : "Enter Valid Amount "
            })
        }
    }
    const handleLeverage = ( time, lkdamt, thash, pid) => {
           console.log(rebuy)
        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "usd": lkdamt,
            "thash" : thash,
            "packId" : pid,
            "tranTime"  : time
        });
        Swal.fire('Kaspa Miner', "Please wait for geting Information", 'info')
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/api/buy`,
            headers: {
                'address': account,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data);
                let  res = response.data;
                    Swal.fire({
                        title : "Kaspa Miner",
                        icon : 'info',
                        text : res?.message
                    })
                    handleWallet()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleWallet = useCallback(() => {

        let data = JSON.stringify({
            "address": account,
            "ip": ipAddress,
            "ulid": loginData.ulid
        });
        setLoading(true)
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}api/package`,
            headers: {
                'address': account,
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                setWallet(response.data)
                
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
            setLoading(false)
    }, [account, ipAddress, loginData])
    useEffect(()=>{
        balance(provider, account, abi, UsdToken).then((val) =>
        {
            console.log(val/1000000000000000000);
            setBal(val/1000000000000000000)
        }
    )
        handleWallet()
      },[account, handleWallet, provider])

    const handleValues=async (e, p)=>{
        console.log(e);
        console.log(bal)
        if(bal >= e && bal > 0)
        {
            setRebuy(e)
            await sendToken(p)
        }
        else
        {
            Swal.fire("Kaspa Miner", "Please Check USDT Bep-20 Balance. Your Balance :"+bal, "info");
        }
    }
    return (
        <>
            <Header />
            {loading ? <><ReactLoader/></> :
                    <div className="page-body m-10">
                        <div className="container-fluid">
                            <div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card shadow-sm">
                                            <div className="card-body ">
                                                <div className="mb-0 text-center">
                                                    <h6 className="m-0 me-2 text-info mb-2">Package Inforamtion</h6>
                                                </div>
                                                <div className="mb-0 d-flex">
                                                    <div className="flex-fill text-truncate ">
                                                        <div className="mb-0 text-info">Wallet USDT</div>
                                                    </div>
                                                    <div className="flex-fill text-truncate text-end">
                                                        <p className="mb-0">${bal.toFixed(3)}  <span className='text-danger mt-0' style={{fontSize:'7px'}}>(USDT BEP-20)</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {wallet?.packages?.map((list, i)=> {
                                        let paymat = (parseFloat(list.per) * parseFloat(wallet?.miner)) / 100;
                                        return (
                                            <>
                                                <div className='col-md-4 mb-2' key={i+1}>
                                                    <div className="card shadow-sm">
                                                        <div className="d-flex card-body">
                                                            <div className="flex-fill text-truncate ">
                                                                <h6 className="mb-0 text-info">{list.package_name}%</h6>
                                                                <div className="mb-0">${paymat} <span className='text-danger mt-0' style={{fontSize:'7px'}}>(USDT BEP-20)</span></div>
                                                            </div>
                                                            <div className="flex-fill text-truncate text-end">
                                                                <button className='btn  btn-info btn-sm shadow-sm' onClick={() => handleValues(paymat, list.id)}>Buy {list.package_name}%</button>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className='text-center mt-0 mb-2'>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        })}
                                </div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card shadow-sm">
                                            <p className="card-body text-info text-center">Package Buy History</p>
                                        </div>
                                    </div>

                                    {wallet?.invest?.map((list, index)=>{
                                        let hr = `https://bscscan.com/tx/${list.tran_hash}`;
                                        let inv = list?.invest_amount;
                                        if(inv > 0)
                                        {
                                            inv = parseFloat(inv).toFixed(3)
                                        }
                                        else
                                        {
                                            inv = 0.00;
                                        }
                                        
                                        return (                    
                                        <div className='col-md-4 mb-2' key={index+1}>                    
                                            <div className='card shadow-sm' >
                                                <div className="card-body">
                                                    <div className="mb-0 d-flex">
                                                        <div className="flex-fill text-truncate ">
                                                            <div className="mb-0 text-info">Date</div>
                                                            <div className="mb-0 text-info">Package</div>
                                                            <div className="mb-0 text-info">Amount</div>
                                                            <div className="mb-0 text-info">Transaction</div>
                                                        </div>
                                                        <div className="flex-fill text-truncate text-end">
                                                            <p className="mb-0">{list.dt}</p>
                                                            <p className="mb-0">{list.pack}%</p>
                                                            <p className="mb-0">${inv}  </p>
                                                            <p className="mb-0"><a href={hr} target='_blank' rel="noreferrer" >View </a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    )})}
                                </div>
                            </div>
                        </div>
                        
                    </div>
            }
            <Footer />                       
        </>
    );
}