import Web3Modal from "web3modal";
import providerOptions from "./providerOptions";

const web3Modal = () =>
  new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
    // theme: "dark",
  });



// export default modal;
export default web3Modal
