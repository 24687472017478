import React from 'react'
import MM from '../images/f.png'
import './ReactLoader.css'

export default function ReactLoader() {
  return (
    <div style={{marginTop:'50%', marginLeft: "40%"}}>
        <img src={MM} alt="Page loading" width={100} height={100} className='pulse-animation' ></img>
    </div>
  )
}
