import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { NetworkContext } from '../context/NetworkContext';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
const config = require('../config.json')

export default function Business({ipAddress, loginData}) {
    const [account, setAccount] = useContext(NetworkContext);
  const [business,setBusiness] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const [businessTable,setBusinessTable] = useState({});
  
  const handleBusiness = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/income`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        setBusiness(response.data)
        console.log(response.data)
      setBusinessTable(response.data.info)
    //   console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {
    
    handleBusiness()
  },[handleBusiness])
    return (
        <>
                
                        <Header />
                        {loading ? <><ReactLoader/></> :
                        <div className="page-body m-10">
                            <div className="container-fluid">
                                <div className='row'>
                                    <div className="col-md-12 ">
                                        <div className="card shadow-sm">
                                            <div className="card-body  ">
                                                <div className="mb-0 text-center">
                                                <h6 className="m-0 me-2  text-warning">Reward Details</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-4 mt-2'>
                                        <div className="card shadow-sm">
                                            <div className="d-flex card-body">
                                            
                                            <div className="flex-fill text-truncate ">
                                                <div className=" mb-0">Total Reward</div>
                                                <span className="small text-info">$ {business?.main?.total}</span>
                                            </div>
                                            <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mt-2'>
                                        <div className="card shadow-sm">
                                            <div className="d-flex card-body">
                                            
                                            <div className="flex-fill text-truncate ">
                                                <div className=" mb-0">Mining Reward</div>
                                                <span className="small text-info">$ {business?.main?.gen}</span>
                                            </div>
                                            <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mt-2'>
                                        <div className="card shadow-sm">
                                            <div className="d-flex card-body">
                                            
                                            <div className="flex-fill text-truncate ">
                                                <div className=" mb-0 ">Associate Reward</div>
                                                <span className="small text-info">$ {business?.main?.passive}</span>
                                            </div>
                                            <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12  mb-2 mt-2">
                                        <div className="card shadow-sm">
                                            <div className="card-body  ">
                                                <div className="mb-0 text-center">
                                                <h6 className="m-0 me-2  text-warning">Reward Details</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                {
                                    business?.data?.map((list, index)=>{
                                        
                                        return ( 
                                                <>
                                                <div className='col-md-4' key={index}>
                                                    <div class="alert shadow-sm ">
                            
                                                        <div class="mt-0">
                                                            <div>{list.type}</div>
                                                            <div class="d-flex flex-wrap justify-content-between small">Date: <span >{list.dt}</span></div>
                                                            <div class="d-flex flex-wrap justify-content-between small">Amount: <span >KAS : {list.netAmount}</span></div>
                                                            <div class="d-flex flex-wrap justify-content-between small">Refrence: <span > {list.refrence}</span></div>
                                                            <div class="d-flex flex-wrap justify-content-between small">Level: <span > L-{list.level}</span></div>
                                                            {/* <div >{list.information}</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                </>)
                                    })}
                                </div>
                                        {/* <div class="card-datatable table-responsive text-center">
                                            <table class="datatable table border-top table-xs table-hover table-bordered table-striped text-center" style={{fontSize : "10px"}}>
                                                <thead >
                                                    <tr>
                                                        <th style={{fontSize:"11px"}}>Type of Income</th>
                                                        <th style={{fontSize:"11px"}}>Level</th>
                                                        <th style={{fontSize:"11px"}}>Refrence</th>
                                                        <th style={{fontSize:"11px"}}>On Total</th>
                                                        <th style={{fontSize:"11px"}}>Per</th>
                                                        <th style={{fontSize:"11px"}} >Amount</th>
                                                        <th style={{fontSize:"11px"}}>Date</th>
                                                        <th style={{fontSize:"11px"}}>Inforamtion</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    business?.data?.map((list, index)=>{
                                                        
                                                        return ( 
                                                                <>
                                                                    <tr id={index}>
                                                                        <td>{list.type}</td>
                                                                        <td>{list.level}</td>
                                                                        <td>{list.refrence}</td>
                                                                        <td>{list.onTotal}</td>
                                                                        <td>{list.asPer}</td>
                                                                        <td>{list.netAmount}</td>
                                                                        <td>{list.dt}</td>
                                                                        <td>{list.information}</td>
                                                                    </tr>
                                                                </>      
                                                        )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        }
                        <Footer />
                   
        </>
    );
}
