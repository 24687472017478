import React, { useContext, useCallback, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import web3Modal from ".././modal";
import { ConnectContext } from '../context/ConnectContext';
import { NetworkContext } from '../context/NetworkContext';
import { ethers } from "ethers";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2'
import logo from '../images/kas.webp'
import { checkStakeInfo } from '../utils';
const config = require('../config.json')

export default function Login({ ipAddress, onLogin }) {
    const [account, setAccount] = useContext(NetworkContext);
    const [provider, setProvider, checkNetwork] = useContext(ConnectContext)
    // const history = useContext(HistoryContext)
    const history = useHistory();

    const connectWallet = useCallback(async () => {
        try {
            const instance = await web3Modal().connect();
            let provider = new ethers.providers.Web3Provider(instance,'any');
            setProvider(provider);
            const accounts = await provider.listAccounts();
            if (accounts) {
                setAccount(accounts[0]);
                return account
            }
            return false
            
        } catch (error) {
            console.error(error?.message);
        }
    }, [account, setAccount, setProvider]);

    const refreshState = useCallback(() => {
        setAccount();
    }, [setAccount]);

    const disconnectWallet = useCallback(async () => {
        try {
            web3Modal().clearCachedProvider();
            refreshState();
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    }, [refreshState]);

    useEffect(() => {
        if (web3Modal().cachedProvider) {
            connectWallet();
        }
    }, [connectWallet]);

    useEffect(() => {
        if (provider?.on) {
            const handleAccountsChanged = (accounts) => {
                if (accounts) setAccount(accounts[0]);
            };

            const handleDisconnect = () => {
                disconnectWallet();
            };
            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("disconnect", handleDisconnect);

            return () => {
                if (provider.removeListener) {
                    provider.removeListener("accountsChanged", handleAccountsChanged);
                    provider.removeListener("disconnect", handleDisconnect);
                }
            };
        }
    }, [disconnectWallet, provider, setAccount]);

    const handleLogin = async (event) => {
        event.preventDefault()
        try {
            let response = await connectWallet()
            if (response) {
                console.log(response)
                setTimeout(async()=>{
                    let res = await checkNetwork()
                    console.log(res)
                    if (res) {
                        login();
                    }
                },100)
            }
        } catch (err) {
            console.log(err?.message)
        }
    }

    const login = async () => {
        let address = account, ip = ipAddress
        let data = JSON.stringify({
            "address": address,
            "ip": ip
        });
        let axiosConfig = {
            method: 'post',
            url: `${config.baseUrl}/api`,
            headers: {
                'address': address,
                'ip': ip,
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(axiosConfig)
        let response = await axios.request(axiosConfig)
        console.log(response)
        response = response.data
        if (response.status) {
            console.log(response,data)
            let loginData = response.data
            loginData.address = address
            setLoginData(loginData)
            onLogin(response.data)
            history.push('/dash');
            
        }
        else if (response.code === 30) {
            Swal.fire({
                icon: 'info',
                title: 'Kaspa Miner',
                text: response?.message
            }).then(() => {
                history.push('/register');
            })
        }
      
    };
    function setLoginData(loginData) {
        localStorage.setItem('loginData', JSON.stringify(loginData));
    }
    return (
        <>
            <div className="page-body auth">
                <div className="container-fluid">
                <div className="row g-0">
                    <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                    <div className="card shadow p-4 p-md-5 m-3" style={{maxWidth: '32rem'}}>
                        
                        <div className="d-flex d-lg-none justify-content-center">
                            <div className="mb-4 text-center">
                                <img src={logo} style={{width : "50%"}} alt='Kaspa Miner' />
                            </div>
                        </div>
                        <div className="col-12 text-center mb-4">
                            <span className="text-primary">Lets Start Minine kaspa. Invest USDT and genrate Kaspa</span>
                            <br/>
                            <span className="text-warning">Please Select Smart chain as EVM Chain</span>
                        </div>
                        
                        <div className="col-12 text-center mt-4">
                            <button className="btn btn-info lift text-uppercase  btn-rounded" onClick={handleLogin} >Connect Wallet</button>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <span className="text-muted">Don't have an account yet? <Link to="/register">Sign up here</Link></span>
                        </div>
                        
                    </div>
                    </div>
                </div> 
                </div>
            </div>
        </>
    );
}
