import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { NetworkContext } from '../context/NetworkContext';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const config = require('../config.json')

export default function Task({ipAddress, loginData}) {
    const [account] = useContext(NetworkContext);
  const [task, setBusiness] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const handleBusiness = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/package`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        setBusiness(response.data)
        console.log(response.data)
      
    //   console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {
    
    handleBusiness()
  },[handleBusiness])

    return (
                <>
                
                        <Header />
                        {loading ? <><ReactLoader/></> :
                        <div className="page-body m-10">
                            <div className="container-fluid">
                                <div className='row'>
                                    <div className="col-md-12 ">
                                        <div className="card shadow-sm">
                                            <div className="card-body  ">
                                                <div className="mb-0 text-center">
                                                <h6 className="m-0 me-2  text-warning">Your Miner Details</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-md-6 mt-2'>
                                        <div className="card shadow-sm">
                                            <div className="d-flex card-body">
                                            
                                            <div className="flex-fill text-truncate ">
                                                <div className=" mb-0">Total Invested</div>
                                                <span className="small text-info">$ {task?.bal?.total_invest}</span>
                                            </div>
                                            <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-2'>
                                        <div className="card shadow-sm">
                                            <div className="d-flex card-body">
                                            
                                            <div className="flex-fill text-truncate ">
                                                <div className=" mb-0">Wallet</div>
                                                <span className="small text-info">KAS {task?.bal?.balance}</span>
                                            </div>
                                            <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i class="fa fa-diamond fa-lg"></i></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                        {
                            task?.myminer?.map((list, index)=>{
                                let link = '';
                                if(list.is_work)
                                {
                                    link = "https://kaspa-pool.org/#/dashboard/'"+list.url+"'";
                                }
                                return ( 
                                        <>
                                        <div className='col-md-4 mb-2' key={index}>
                                            <div class="alert shadow-sm ">
                    
                                                <div class="mt-0">
                                                    <div>{list.package_name} ({list.mk_name} {list.per} %)</div>
                                                    <div class="d-flex flex-wrap justify-content-between small">Invested Amount: <span >$ {parseFloat(list.invested_amount).toFixed(3)}</span></div>
                                                    <div class="d-flex flex-wrap justify-content-between small">Reward Collected: <span >$ {parseFloat(list.paid_amt_usd).toFixed(3)}</span></div>
                                                    <div class="d-flex flex-wrap justify-content-between small">Link: <span > <a href={link}target='_blank' rel="noreferrer" >View</a></span></div>
                                                    
                                                    {/* <div >{list.information}</div> */}
                                                </div>
                                            </div>
                                            <div class="card shadow-sm ">
                                                <div class="mt-0">
                                                    <iframe src={link} title={index+1} width="100%" height={'620px'} scrolling="no" ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        </>)
                            })}
                        </div>

                            </div>
                        </div>
                        
                        }
        </>
    );
}
