import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { NetworkContext } from '../context/NetworkContext';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
const config = require('../config.json')


export default function Downline({ ipAddress, loginData }) {
  const [account, setAccount] = useContext(NetworkContext);
  const [downline,setDownline] = useState({});
  const [downlineTable,setDownlineTable] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const [limit, setLimit] = useState(0)
  
  const handleDownline = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/downline`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
    .then((response) => {
      setDownline(response.data)
      // setDownline(config.downline)
      setDownlineTable(response.data.downList)
       console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });    
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])
  
  useEffect(() => {
    handleDownline()
  },[handleDownline])

  return (
    <>
        
            <Header />
            {loading ? <><ReactLoader/></> :
            <div className="page-body m-10">
              <div className="container-fluid">
                <div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div className="mb-0 d-flex">
                                    <div class="flex-fill text-truncate ">
                                        <div class="mb-0 text-info">Total Registration</div>
                                    </div>
                                    <div class="flex-fill text-truncate text-end">
                                        <p class="mb-0 text-warning">{downline.total_downlin || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div className="mb-0 d-flex">
                                    <div class="flex-fill text-truncate ">
                                        <div class="mb-0 text-info">Active Members</div>
                                    </div>
                                    <div class="flex-fill text-truncate text-end">
                                        <p class="mb-0 text-warning">{downline.Active_downline || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div className="mb-0 d-flex">
                                    <div class="flex-fill text-truncate ">
                                        <div class="mb-0 text-info">In-Active Members</div>
                                    </div>
                                    <div class="flex-fill text-truncate text-end">
                                        <p class="mb-0 text-warning">{downline.Inactive_downline || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                  <div className="flex-fill text-truncate ">
                                      <div className=" mb-0">Total Invested</div>
                                      <span className="small text-info">$ {downline?.total_invest}</span>
                                  </div>
                                  <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                              </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                  <div className="flex-fill text-truncate ">
                                      <div className=" mb-0">Total Business</div>
                                      <span className="small text-info">$ {downline?.total_business}</span>
                                  </div>
                                  <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                              </div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2'>
                            <div className="card shadow-sm">
                                <div className="d-flex card-body">
                                  <div className="flex-fill text-truncate ">
                                      <div className=" mb-0">Diract Business</div>
                                      <span className="small text-info">$ {downline?.diract_business}</span>
                                  </div>
                                  <div className="avatar rounded-circle no-thumbnail bg-success text-light text-end"><i className="fa fa-dollar fa-lg"></i></div>
                              </div>
                            </div>
                        </div>
                        
                    </div>
                  </div>
                  
                  <div className='row'>
                  {
                              downline?.downList?.map((list, index)=>{
                                let stake = list?.invest;
                                if(stake > 0)
                                {
                                  stake = parseFloat(stake).toFixed(3);
                                } 
                                else
                                {
                                  stake = 0
                                }

                                return ( 
                                  <>
                                    <div className='col-md-4 mb-2' key={index}>
                                            <div class="alert shadow-sm ">
                    
                                                <div class="mt-0">
                                                    <div>{list.name} ({list.uslid} <span className={`${(list.status === 'ACTIVE')?'text-info text-sm':'text-danger text-sm'}`} style={{fontSize:"10px"}}>{list?.status}</span>)</div>
                                                    <div class="d-flex flex-wrap justify-content-between small">Invested Amount: <span >$ {stake || 0}</span></div>
                                                    <div class="d-flex flex-wrap justify-content-between small">Level: <span >$ {list?.level}</span></div>
                                                    <div class="d-flex flex-wrap justify-content-between small">Sponsor: <span > {list?.splid}</span></div>
                                                    
                                                    {/* <div >{list.information}</div> */}
                                                </div>
                                            </div>
                                        </div>
                                  </>
                                // <div className="col-md-4  mb-1" key={index+1}>
                                //   <div className="card shadow-sm">
                                //     <div className="card-body " >
                                //       <div className="col-md-12">
                                //         <div className='row d-flex '>
                                //           <div className='col-6 text-left'>
                                //             <span className="text-white text-sm">ID - {list?.uslid}</span>
                                //           </div>
                                //           <div className='col-6' style={{textAlignLast:"end"}}>
                                //             <span className={`${(list.status === 'ACTIVE')?'text-info text-sm':'text-danger text-sm'}`} style={{fontSize:"14px"}}>{list?.status}</span>
                                //           </div>
                                //         </div>
                                //         <div className='row d-flex '>
                                //           <div className='col-6 text-left'>
                                //               <small className="text-sm">Sponsor : {list?.splid}</small>
                                //           </div>
                                //           <div className='col-6' style={{textAlignLast:"end"}}>
                                //             <small className="text-sm">DOJ : {list?.adate}</small>
                                //           </div>
                                //         </div>
                                //         <div className='row d-flex '>
                                //           <div className='col-6 text-left'>
                                //               <small className="text-sm">Level : </small>
                                //           </div>
                                //           <div className='col-6' style={{textAlignLast:"end"}}>
                                //             <small className="text-sm">{list?.level}</small>
                                //           </div>
                                //         </div>
                                //         <div className='row d-flex '>
                                //           <div className='col-6 text-left'>
                                //               <small className="text-sm">Total Invested : </small>
                                //           </div>
                                //           <div className='col-6' style={{textAlignLast:"end"}}>
                                //             <small className="text-sm">${stake || 0}</small>
                                //           </div>
                                //         </div>
                                //       </div>
                                //     </div>
                                //   </div>
                                // </div>
                                )
                                })
                  }
                  </div>
                  
                </div>
              </div>
              
            </div>}
            <Footer />
    </>
  );
}