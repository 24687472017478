import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { NetworkContext } from '../context/NetworkContext';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Swal from 'sweetalert2';
const config = require('../config.json')

export default function Profile({ipAddress, loginData}) {
    const [account] = useContext(NetworkContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [kaspa, setKaspa] = useState('');
  
  const handleProfile = useCallback(() => {
    
    let data = JSON.stringify({
      "address": (loginData.address)?loginData.address:account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/profile`,
      headers: { 
        'address': (loginData.address)?loginData.address:account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
      // console.log(response.data)
        setName(response.data.data?.uname)
        setEmail(response.data.data?.uemail)
        setMobile(response.data.data?.umobile)
        setKaspa(response.data.data?.kaspa)   
    })
    .catch((error) => {
      // console.log(error);
    });
  },[account, ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])

  
  useEffect(() => {
   handleProfile()
  },[handleProfile])

  const handleProfileEdit = (e) => {
    e.preventDefault()
    let data = JSON.stringify({
      "address": (loginData.address)?loginData.address:account,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      "uname" : name,
      "uemail"  : email,
      "umobile" : mobile,
      "kaspa" : kaspa
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/api/profileedit`,
      headers: { 
        'address': (loginData.address)?loginData.address:account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        setName(response.data.data?.uname)
        setEmail(response.data.data?.uemail)
        setMobile(response.data.data?.umobile)  
        Swal.fire('Meros Media', response.data?.message, 'info');
    })
    .catch((error) => {
      console.log(error);
    });
  }

    return (
        <>
                <Header />
                      <div className="page-body m-10">
                        <div className="container-fluid">
                          <div className='row'>
                                <div className="col-md-12  mb-2">
                                    <div className="card shadow-sm">
                                        <div className="card-body ">
                                            <div className="mb-0 text-center">
                                                <h6 className="m-0 me-2 text-info mb-2">Profile Inforamtion</h6>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className="card mb-4 shadow-sm">
                                            
                                            <div className="card-body">
                                                <form id="formAccountSettings" onSubmit={handleProfileEdit} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                                    <div className="row">
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="companyName" className="form-label">Full Name</label>
                                                            <input type="text" id="companyName" name="companyName" className="form-control" placeholder="Your Name" value={name|| ''} onChange={(e) => {setName(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="email" className="form-label">Email</label>
                                                            <input className="form-control" type="text" id="email" name="email" placeholder="Email ID"  value={email|| ''} onChange={(e) => {setEmail(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="mobile" className="form-label">Contact Number</label>
                                                            <input className="form-control" type="text" id="mobile" name="mobile" placeholder="Contact Number"  value={mobile|| ''} onChange={(e) => {setMobile(e.target.value)}}/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="taxId" className="form-label">BNB Address</label>
                                                            <input className="form-control" type="text"  value={account}  readOnly/>
                                                        </div>
                                                        <div className="mb-3 col-sm-6">
                                                            <label htmlFor="taxId" className="form-label">Kaspa Wallet Address</label>
                                                            <input className="form-control" type="text"  value={kaspa|| ''} onChange={(e) => {setKaspa(e.target.value)}} />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 text-center">
                                                        <button type="submit" className="btn me-2 text-sm btn-warning">Update changes</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <Footer />
                   
        </>
    );
}