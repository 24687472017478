import React, { useContext, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { NetworkContext } from '../context/NetworkContext';
import axios from 'axios';
import Swal from 'sweetalert2'
import web3Modal from ".././modal";
import { ethers } from "ethers";
import { ConnectContext } from '../context/ConnectContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../images/kas.webp'
const config = require('../config.json')

export default function Register({ ipAddress }) {
    const [account, setAccount] = useContext(NetworkContext);
    const [provider, setProvider, checkNetwork] = useContext(ConnectContext)
    const [splid, setSplid] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const history = useHistory();
    const handleRegister = async (e) => {
        e.preventDefault()

        let response = await connectWallet()
        if (response) {
            console.log(response)
            setTimeout(async () => {
                let res = await checkNetwork()
                if (res) {
                    if(email.length < 5) { 
                        Swal.fire({
                            icon: 'info',
                            title: 'Kaspa Miner',
                            text: 'Please enter Valid email'
                        })
                        return false;
                    }
                    if(name.length < 5) { 
                        Swal.fire({
                            icon: 'info',
                            title: 'Kaspa Miners',
                            text: 'Please enter Valid Name'
                        })
                        return false;
                    }
                    let data = JSON.stringify({
                        "splid": splid,
                        "address": account,
                        "name"  : name,
                        'email' : email,
                        'mobile'    : contact,  
                        "ip": ipAddress
                    });

                    let axiosConfig = {
                        method: 'post',
                        url: `${config.baseUrl}/api/register`,
                        headers: {
                            'address': account,
                            'ip': ipAddress,
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                       console.log(axiosConfig)
                    axios.request(axiosConfig)
                        .then((response) => {
                            // console.log(JSON.stringify(response));
                            let res = (response.data);
                            // console.log(res.message);
                            if (res.status) {
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Kaspa Miner',
                                    text: res.message
                                })
                            }
                            else {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Kaspa Miner',
                                    text: res.message
                                })
                                if (res.code === 30) {
                                    history.push('/');
                                }
                            }

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }, 100)
        }


    }
    const handleSlid = (e) => {
        e.preventDefault()
        checkSplid(splid);
    }
    

    function checkSplid(slid) {
        if (slid.length > 4 ) {
            let data = JSON.stringify({
                "splid": slid,

            });

            let axiosConfig = {
                method: 'post',
                url: `${config.baseUrl}/api/checksponsor`,
                headers: {
                    'address': account,
                    'ip': ipAddress,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //   console.log(axiosConfig)
            axios.request(axiosConfig)
                .then((response) => {
                    console.log(response);
                    let res = (response.data);
                     
                    if (res.status) {
                        setSplid(slid)
                        Swal.fire({
                            icon: 'info',
                            title: 'Kaspa Miner',
                            text: res.message
                        })
                    }
                    else {

                        Swal.fire({
                            icon: 'warning',
                            title: 'Kaspa Miner',
                            text: res.message
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }


    const connectWallet = useCallback(async () => {
        try {
            //console.log("Wallet connect called");
            const instance = await web3Modal().connect();
            // setInstance(instance);
            let provider = new ethers.providers.Web3Provider(instance, 'any');
            setProvider(provider);
            const accounts = await provider.listAccounts();
            if (accounts) {
                setAccount(accounts[0]);
                return account
            }
            return false
            //console.log(account)
        } catch (error) {
            console.error(error?.message);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: error?.message
            // })
        }
    }, [account, setAccount, setProvider]);
    useEffect(() => {
        if (web3Modal().cachedProvider) {
            connectWallet();
        }
    }, [connectWallet]);

    useEffect(() => {
        if (provider?.on) {
            const handleAccountsChanged = (accounts) => {
                // console.log("accountsChanged", accounts);
                if (accounts) setAccount(accounts[0]);
            };

            //   const handleDisconnect = () => {
            //     console.log("disconnect", error);
            //     disconnectWallet();
            //   };

            provider.on("accountsChanged", handleAccountsChanged);
            //   provider.on("disconnect", handleDisconnect);

            return () => {
                if (provider.removeListener) {
                    provider.removeListener("accountsChanged", handleAccountsChanged);
                    //   provider.removeListener("disconnect", handleDisconnect);
                }
            };
        }
    }, [provider, setAccount]);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const type = queryParameters.get("slid")
         //console.log(type.length);
        if (type) {
           // checkSplid(type);
           setSplid(type)
        }
    })
    return (
        <>
            <div className="page-body auth">
                <div className="container-fluid">
                <div className="row g-0">
                    {/* <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center">
                    <div style={{maxWidth: '25rem'}}>
                        <div className="mb-4">
                            <img src={logo} style={{width : "50%"}} alt='Kaspa Miners' />
                        </div>
                        <div className="mb-5">
                        <h2 className="color-900">Build digital products with:</h2>
                        </div>
                        <ul className="list-unstyled mb-5">
                        <li className="mb-4">
                            <span className="d-block mb-1 fs-4 fw-light">All-in-one tool</span>
                            <span className="color-600">Amazing Features to make your life easier &amp; work efficient</span>
                        </li>
                        <li>
                            <span className="d-block mb-1 fs-4 fw-light">Easily add &amp; manage your services</span>
                            <span className="color-600">It brings together your tasks, projects, timelines, files and more</span>
                        </li>
                        </ul>
                        
                    </div>
                    </div> */}
                    <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                    <div className="card shadow p-4 p-md-5" style={{maxWidth: '32rem'}}>
                        <form className="row g-2">
                        <div className="d-flex d-lg-none justify-content-center">
                            <div className="mb-4 text-center">
                                <img src={logo} style={{width : "50%"}} alt='Kaspa Miners' />
                            </div>
                        </div>
                        <div className="col-12 text-center mb-4">
                        <span className="text-primary">Lets Start Minine kaspa. Invest USDT and genrate Kaspa</span>
                            <br/>
                            <span className="text-warning">Please Select Smart chain as EVM Chain</span>
                        </div>
                        <div className="col-12">
                            <div className="mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Your Sponsor ID" required value={splid} onChange={(e) => setSplid(e.target.value)} onBlur={handleSlid} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Full Name" required value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-2">
                                <input type="email" className="form-control form-control-sm" placeholder="Enter Email ID" required value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Contact Number" value={contact} onChange={(e) => setContact(e.target.value)} />
                            </div>
                        </div>
                        
                        <div className="col-12 text-center mt-4">
                            <button className="btn btn-sm btn-info lift text-uppercase btn-sm" onClick={handleRegister} >Register Now</button>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <span className="text-muted">Allready Register ? <Link to="/">Login here</Link></span>
                        </div>
                        </form>
                    </div>
                    </div>
                </div> 
                </div>
            </div>
        </>
    );
}
