import React, { useContext, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ConnectContext } from '../context/ConnectContext';
import { NetworkContext } from '../context/NetworkContext';
import { IpContext } from '../context/IpContext';
import web3Modal from ".././modal";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { MobileSidebarContext } from '../context/MobileSidebarContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
const config = require('../config.json')

export default function Menu() {
  const [account, setAccount] = useContext(NetworkContext);
  const [provider, setProvider] = useContext(ConnectContext)
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const [isSubWalletOpen, setIsSubWalletOpen] = useState(false)
  const [ipAddress] = useContext(IpContext);
  const [mobileOpen, setMobileOpen] = useContext(MobileSidebarContext)
  const { height, width } = useWindowDimensions();

  const history = useHistory();
  const refreshState = useCallback(() => {
    setAccount();
  }, [setAccount]);

  const disconnectWallet = useCallback(async () => {
    try {
      // console.log("Wallet disconnect called");
      web3Modal().clearCachedProvider();
      // setAccount([])
      refreshState();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }, [refreshState]);

  useEffect(() => {
    if (provider?.on) {
      // const handleAccountsChanged = (accounts) => {
      //   console.log("accountsChanged", accounts);
      //   if (accounts) setAccount(accounts[0]);
      // };

      const handleDisconnect = () => {
        // console.log("disconnect");
        disconnectWallet();
      };

      // provider.on("accountsChanged", handleAccountsChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          // provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [disconnectWallet, provider, setAccount]);

  const handleLogout = async () => {
    // disconnectWallet()
    try {
      const loginData = JSON.parse(localStorage.getItem('loginData'));
      let data = JSON.stringify({
        "address": account,
        "ip": ipAddress,
        "ulid": loginData?.ulid
      });

      let axiosConfig = {
        method: 'post',
        url: `${config.baseUrl}/api/logout`,
        headers: {
          'address': account,
          'ip': ipAddress,
          'ulid': '6049090',
          'auth': loginData?.auth,
          'token': loginData?.token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      let response = await axios.request(axiosConfig)
      response = response.data
      // console.log('logging out')
      // console.log(response)
      localStorage.removeItem('loginData');
      history.push('/');

    } catch (error) {
      console.error(error)
    }
  };

  const handleMouseEnter = () => {
    if (width > 1199.98) {
      setTimeout(() => setIsOpen(true), 100)
    }
    // setTimeout(()=>setIsSubMenuOpen(true),100)

  };

  const handleMouseLeave = () => {
    if (width > 1199.98) {
      setTimeout(() => setIsOpen(false), 100)
      setIsSubMenuOpen(false)
      setIsSubWalletOpen(false)
    }
    // setIsSubMenuOpen(false)
  };
  let sidebarStyle = {}
  if (width > 1199.98) {
    sidebarStyle = {
      // display: isOpen ? 'block' : 'none',
      width: isOpen ? '16.25rem' : '5rem'

      // Add other styles as needed
    }
  } else {
    if (mobileOpen) {
      sidebarStyle = {
        // display: isOpen ? 'block' : 'none',
        transform: 'translate3d(0, 0, 0)'
        // Add other styles as needed
      }
    } else {
      sidebarStyle = {
        // display: isOpen ? 'block' : 'none',
        transform: 'translate3d(-100%, 0, 0)'
        // Add other styles as needed
      }
    }

  }

  const subMenuStyle = {
    display: isSubMenuOpen ? 'block' : 'none',
  }
  const subWalletStyle = {
    display: isSubWalletOpen ? 'block' : 'none',
  }

  useEffect(() => {
    //console.log("mobile open",mobileOpen)
    if (mobileOpen) {
      setIsOpen(true)
      // setIsSubMenuOpen(true)
    } else {
      setIsOpen(false)
      // setIsSubMenuOpen(false)
    }
  }, [mobileOpen])
  return (

    <>
    </>
  );
}
